import type { UserDetailsWithTimestamp, UserDetailsResponse } from "~/types/user";
const config = useRuntimeConfig();

export const useUserDetails = defineStore(
    "userDetails",
    () => {
        const userDetails = ref<UserDetailsWithTimestamp>({
            accessToken: "",
            accountType: "",
            fullName: "",
            refreshToken: "",
            updatedAt: "",
        });

        const getAccessToken = computed(() => {
            return userDetails.value.accessToken;
        });

        const LTRS = ref(0);
        const updateLTRS = () => {
            LTRS.value += 1;
        }

        const updateUserLoginDetails = (data: any) => {
            userDetails.value.accessToken = data.accessToken;
            userDetails.value.accountType = data.accountType;
            userDetails.value.fullName = data.fullName;
            userDetails.value.refreshToken = data.refreshToken;
            userDetails.value.updatedAt = new Date().toISOString();
            LTRS.value = 0;
        }

        const logoutUser = async () => {
            function clearUserDetails() {
                userDetails.value.accessToken = "";
                userDetails.value.accountType = "";
                userDetails.value.fullName = "";
                userDetails.value.refreshToken = "";
                userDetails.value.updatedAt = "";
            }

            const data = await $fetch<UserDetailsResponse>("/auth/logout", {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${getAccessToken.value}`,
                },

                async onRequestError({ request, options, error }) {
                    throw createError(useErrorHandler(error, "onRequestError - useUserDetails.logoutUser") as string);
                },

                async onResponseError({ request, response, options }) {
                    throw createError(useErrorHandler(response, "useUserDetails.logoutUser") as string);
                },
            }).catch((err) => {
                console.error(err);
                throw createError(err.message);
            });

            clearUserDetails();
        };

        const refreshToken = async () => {
            const data = await $fetch<UserDetailsResponse>("/auth/refreshToken", {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${getAccessToken.value}`,
                },
                async onRequestError({ request, options, error }) {
                    throw createError(useErrorHandler(error, "onRequestError - useUserDetails.refreshToken") as string);
                },

                async onResponseError({ request, response, options }) {
                    throw createError(useErrorHandler(response, "useUserDetails.refreshToken") as string);
                },
            }).catch((err) => {
                console.error(err);
            });

            if (typeof data !== "object") return; // Make sure it is an object before checking 'respCode' in data else it will throw an fatal error
            if (data && "respCode" in data && data.respCode === 2000 && data.result) {
                let newProfileInfo = data.result;

                userDetails.value = {
                    ...userDetails.value,
                    accessToken: newProfileInfo.accessToken,
                    accountType: newProfileInfo.accountType,
                    fullName: newProfileInfo.fullName,
                    refreshToken: newProfileInfo.refreshToken,
                    updatedAt: new Date().toISOString(),
                };
            }
        };

        // check if the current updatedAt is older than a day
        const shouldRefreshToken = computed(() => {
            const now = new Date();
            const updatedAt = new Date(userDetails.value.updatedAt);
            const diff = now.getTime() - updatedAt.getTime();
            const diffInHours = diff / (1000 * 3600);
            return [diffInHours > 0.5, diffInHours]; // TODO: change to 24 hours [diffInHours > 24, diffInHours]
        });

       
        return {
            userDetails,
            getAccessToken,
            updateUserLoginDetails,
            logoutUser,
            shouldRefreshToken,
            refreshToken,
            LTRS,
            updateLTRS
        };
    },
    {
        persist: true,
    }
);
